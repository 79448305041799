import { useNavigate } from "react-router-dom";
import { HeaderLK } from "../Components/HeaderLK";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Radar } from "react-chartjs-2";
import left from "../icons/more-group-left.png";
import right from "../icons/more-group-right.png";
import Footer from "../Components/Footer";
import "chart.js/auto";
import { useResize } from "../Hooks/useResize";
import arrow from "../icons/arrow.png";
import { color } from "chart.js/helpers";

export function Statistics() {
  const navigate = useNavigate();
  const [loading, setLoad] = useState();
  const [switchGraph, setSwitchGraph] = useState(true);

  const emptyChart = ["", "", "", "", "", ""];

  const labelInfo = [
    "Состав домохозяйств",
    "Уровень жизни",
    "Работа и образование",
    "Отягощения",
    "Социальная активность",
    ["Здоровье и                    ", "физическая активность"],
  ];

  const chartBackground = [
    "#8ebfff",
    "#00A6B4",
    "#50c6ca",
    "#4d7fbe",
    "#3b71b86e",
    "#004094",
    "#390094",
  ];

  const chartBackgroundSU = [
    "#4d7fbe",
    "#8ebfff",
    "#00A6B4",
    "#50c6ca",
    "#3b71b86e",
    "#004094",
    "#390094",
  ];

  const chartHover = [
    "#3b71b88f",
    "#00a5b47a",
    "#50c6ca8c",
    "#4d80be80",
    "#3b71b82d",
    "#00409475",
    "3900946b",
    "b002db6b",
  ];

  const width = useResize();

  // const pieChartData = {
  //   labels: labelInfo,
  //   datasets: [
  //     {
  //       label: "Баллы",
  //       backgroundColor: chartBackground,
  //       hoverBackgroundColor: chartHover,
  //       data: JSON.parse(localStorage.getItem("graphList")),
  //     },
  //   ],
  // };

  const recomendationText = localStorage.getItem("recomendationText");

  const radarChartData = {
    labels: labelInfo,
    datasets: [
      {
        label: "Социальный статус (текущий)",
        backgroundColor: chartBackground,
        borderColor: "#8ebfff",
        fill: false,
        hoverBackgroundColor: chartHover,
        data: JSON.parse(localStorage.getItem("graphList")),
      },
    ],
  };

  const [radarChartDataSU, setRadarChartDataSU] = useState(
    {
      labels: labelInfo,
      datasets: [
        {
          label: "Социальный уровень (прогнозный)",
          backgroundColor: chartBackgroundSU,
          borderColor: "#4d7fbe",
          fill: false,
          hoverBackgroundColor: chartHover,
          data: JSON.parse(localStorage.getItem("graphListSU")),
        },
      ],
    } || {}
  );

  const radarChartDataOne = {
    labels: labelInfo,
    datasets: [
      {
        label: "Социальный статус (текущий)",
        backgroundColor: "#8ebfff",
        borderColor: "#8ebfff",
        fill: false,
        hoverBackgroundColor: chartHover,
        data: JSON.parse(localStorage.getItem("graphList")),
      },
      {
        label: "Социальный уровень (прогнозный)",
        backgroundColor: "#4d7fbe",
        borderColor: "#4d7fbe",
        fill: false,
        hoverBackgroundColor: chartHover,
        data: JSON.parse(localStorage.getItem("graphListSU")),
      },
    ],
  };

  useEffect(() => {
    if (localStorage.getItem("graphList") == null) {
      navigate("/");
    }

    if (localStorage.getItem("socialLevel") == null) {
      setSwitchGraph(false);
    }
  }, []);

  async function startTest(e) {
    e.preventDefault();

    // setLoad(true);
    // await axios
    //   .get("https://bot.rgsu.net/we/we.py", {
    //     params: {
    //       fullName: localStorage.getItem("fullName"),
    //       email: localStorage.getItem("email"),
    //       gender: localStorage.getItem("gender"),
    //       birthDate: localStorage.getItem("birthDate"),
    //       language: localStorage.getItem("language"),
    //       place: localStorage.getItem("place"),
    //       next: "start",
    //     },
    //   })
    //   .then((response) => {
    //     setLoad(false);
    //     const parser = new DOMParser();
    //     const xml = parser.parseFromString(response.data, "application/xml");

    //     localStorage.setItem("pageNumber", 0);

    //     localStorage.setItem(
    //       "questionNumber",
    //       xml.querySelector("step").textContent
    //     );
    //     localStorage.setItem(
    //       "question",
    //       xml.querySelector("query").textContent
    //     );
    //     let answers = xml.querySelectorAll("ans");
    //     let answersList = [];

    //     for (let i = 0; i < answers.length; i++) {
    //       answersList.push(answers[i].textContent);
    //     }

    //     localStorage.setItem("answers", JSON.stringify(answersList));

    //     let answersId = xml.querySelectorAll("id");
    //     let answersIdList = [];

    //     for (let i = 0; i < answersId.length; i++) {
    //       answersIdList.push(answersId[i].textContent);
    //     }

    //     localStorage.setItem("id", JSON.stringify(answersIdList));
    //     localStorage.setItem("multi", xml.querySelector("multi").textContent);
    //     localStorage.setItem(
    //       "sessionid",
    //       xml.querySelector("sessionid").textContent
    //     );
    //     localStorage.setItem("tdelta", xml.querySelector("tdelta").textContent);
    //   });

    window.scrollTo(0, 0);
    navigate("/registration");
  }

  return (
    <div>
      <HeaderLK></HeaderLK>
      <div className="backGroundLeft">
        <img src={left}></img>
      </div>
      <div className="backGroundRight">
        <img src={right}></img>
      </div>
      <div className="personalInfo">
        <div className="personalSpan">
          <span>Личные данные</span>
        </div>
        <div className="personalContent">
          {localStorage.getItem("statisticsName") ? (
            <div className="personalConentItem">
              Имя или псевдоним:{" "}
              <div>{localStorage.getItem("statisticsName")}</div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="personalConentItem">
            Возраст: <div>{localStorage.getItem("statisticsAge")}</div>
          </div>
        </div>
      </div>
      <div className="personalSpan">
        <span>Дорогой друг!</span>
      </div>
      <div className="personalText">
        <div className="friendText"></div>
        <div className="personalTextContent">
          <div>
            Хотим Вам напомнить, на основании каких наших вопросов мы смогли
            сконструировать полученные значения социального статуса и
            социального уровня. Это поможет Вам сориентироваться в Ваших
            жизненных планах, и мы надеемся, они успешно реализуются.
          </div>
          <div>
            Итак, Вы поделились с нами своим <b> семейным положением</b>,
            которое имеет большое значение в вопросе реализации Ваших
            устремлений и надежд. И это нам кажется правильным, ведь именно
            семья дает силы и опору в нашей зачастую непростой жизни.
          </div>
          <div>
            Еще один важный блок вопросов — Ваш <b>уровень жизни</b>, жилищные
            условия, движимое и недвижимое имущество. Нам важно, чтобы Вы и Ваша
            семья не боролись за каждодневное физическое выживание, не
            чувствовали себя обделенными тем, что должно соответствовать
            атрибутам нормальной жизни.
          </div>
          <div>
            Именно для этого мы поинтересовались Вашими <b>образованием</b> и{" "}
            <b>работой</b>, потому что только они могут создать основу, личного
            и семейного благополучия.
          </div>
          <div>
            Но человек — это не только его материальные запросы, но и социально
            приемлемое поведение,{" "}
            <b>не допускающее просроченных долгов и кредитов</b>, нарушения
            законов. Надеемся, что, отвечая на вопросы о наличии у Вас этих
            «отягощений», Вы ответили «не имеются».
          </div>
          <div>
            Ваша <b>социальная активность</b>, наличие активных аккаунтов в
            социальных сетях, государственных наград, участие в общественной
            жизни говорит о Вас как о человеке, небезразличном к интересам
            общества и государства. Это очень важная часть Вашего социального
            капитала!
          </div>
          <div>
            И, наконец, последнее, о чем Вас спрашивали, но это чрезвычайно
            важная тема — Ваше <b>состояние здоровья и физическая активность</b>
            . Если здесь все в порядке, то у Вас есть хороший шанс стать
            долгожителем, который наслаждается пребыванием в своем «серебряном
            возрасте».
          </div>
          <div>Здоровья Вам и удачи!</div>
        </div>
      </div>
      <div className="personalSpan">
        <span>Результаты</span>
      </div>
      {localStorage.getItem("socialLevel") ? (
        <div className="switchGraphBox">
          <div
            className={
              switchGraph
                ? "switchGraphLeft chosenGraphSwitch"
                : "switchGraphLeft"
            }
            onClick={() => setSwitchGraph(true)}
          >
            На одном графике
          </div>
          <div
            className={
              !switchGraph
                ? "switchGraphRight chosenGraphSwitch"
                : "switchGraphRight"
            }
            onClick={() => setSwitchGraph(false)}
          >
            Разделить графики
          </div>
        </div>
      ) : (
        <></>
      )}

      {switchGraph ? (
        <>
          <div className="socialStatus">
            Социальный статус (текущий):{" "}
            <div className="socialStatusNumber">
              {localStorage.getItem("socialStatus")}
            </div>
            <br></br>Социальный уровень (прогнозный):{" "}
            <div className="socialLevelNumber">
              {localStorage.getItem("socialLevel")}
            </div>
          </div>{" "}
          <div className="pieChart">
            <Radar
              data={radarChartDataOne}
              options={
                width.width > 500
                  ? {
                      legend: {
                        display: false,
                      },
                      scale: {
                        ticks: { display: false },
                      },
                    }
                  : {
                      legend: {
                        display: false,
                      },
                      scales: {
                        r: {
                          pointLabels: {
                            display: false,
                          },
                        },
                      },
                    }
              }
            />
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="socialStatus">
            Социальный статус (текущий): {localStorage.getItem("socialStatus")}
          </div>
          <div className="pieChart">
            {" "}
            <Radar
              data={radarChartData}
              options={
                width.width > 500
                  ? {
                      legend: {
                        display: false,
                      },
                      scale: {
                        ticks: { display: false },
                      },
                    }
                  : {
                      legend: {
                        display: false,
                      },
                      scales: {
                        r: {
                          pointLabels: {
                            display: false,
                          },
                        },
                      },
                    }
              }
            />
          </div>
          {localStorage.getItem("socialLevel") ? (
            <>
              <div className="socialStatus">
                Социальный уровень (прогнозный):{" "}
                {localStorage.getItem("socialLevel")}
              </div>
              <div className="pieChart">
                {" "}
                <Radar
                  data={radarChartDataSU}
                  options={
                    width.width > 500
                      ? {
                          legend: {
                            display: false,
                          },
                          scale: {
                            ticks: { display: false },
                          },
                        }
                      : {
                          legend: {
                            display: false,
                          },
                          scales: {
                            r: {
                              pointLabels: {
                                display: false,
                              },
                            },
                          },
                        }
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {recomendationText && (
        <div
          className="succesfulPersonalText"
          dangerouslySetInnerHTML={{ __html: recomendationText }}
        ></div>
      )}

      <div className="registrationEndBox">
        {loading ? (
          <ReactLoading
            type="spinningBubbles"
            color="black"
            height={"50px"}
            width={"50px"}
          />
        ) : (
          <button className="registrationEnd" onClick={startTest}>
            <span>Попробовать ещё раз</span>
            <img alt="" src={arrow} />
          </button>
        )}
      </div>
      <Footer></Footer>
    </div>
  );
}
